import { SupportDevices } from '@/config/device'
import { GetQueryString } from '@/utils/getQueryString'

export const isMobile = () => {
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    .test(window.navigator.userAgent)
}

export const isIPhone = () => {
  return /(iPhone|iPod|iOS|iPad)/i
    .test(window.navigator.userAgent)
}

export const getIOSVersion = () => {
  var userAgent = navigator.userAgent;
  var regex = /os (\d+)_(\d+)_?(\d+)?/i;
  var matches = userAgent.match(regex);
  if (matches && matches.length > 1) {
    return parseFloat(matches[1] + '.' + matches[2] + (matches[3] ? matches['3'] : '0'));
  }
  return null;
}

export const isSafari = () => {
  return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
}

export const isWeChatBrowser = () => { //包含PC版微信浏览器
  //MicroMessenger/7.0.20.1781(0x6700143B) WindowsWechat
  //MicroMessenger/8.0.20(0x1800142e) NetType/WIFI Language/zh_CN miniProgram
  return /MicroMessenger/i.test(window.navigator.userAgent) && !/miniProgram/i.test(window.navigator.userAgent)
}

/**
 * 
 * @returns 当前设备是否开放支持美颜
 */
export const isSupportFace = () => {
  //从url 参数上获取客户端机型
  if(location.search.indexOf("client") > -1) {
    let client = decodeURIComponent(GetQueryString('client') || '')
    console.error('client', client)
    return SupportDevices.find(device => client && client.indexOf(device) > -1) 
  }
  return false
}
